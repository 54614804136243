/* eslint no-console:0 */
// This is the entrypoint for the `gilligans` child theme. The files
// imported here will be included on every page of the gilligans website.
//
// To reference this file, add <%= javascript_pack_tag 'gilligans' %> to the appropriate
// layout file, like frontend/gilligans/layouts/application.haml
import 'shared/assets/css'
import 'gilligans/components/header/header'
import 'gilligans/components/footer/footer'
import 'gilligans/components/newsletter_signup/newsletter_signup'
import 'gilligans/components/event_inquiry/event_inquiry'
import 'gilligans/components/popup_notification/popup_notification'
import 'shared/components'
import 'shared/assets/javascript'
import './css'
import './javascript'
