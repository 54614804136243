import throttle from 'lodash/throttle';
import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    this.headerDrawer = document.querySelector('.js-header-drawer');
    this.hamburgerToggle = document.querySelectorAll('.js-hamburger-toggle');
    this.navItems = document.querySelectorAll('.js-nav-items a');
    this.hamburgerToggle.forEach((el) => {
      el.addEventListener('click', this.toggleMobileMenu.bind(this));
    });
    this.navItems.forEach((el) => {
      el.addEventListener('click', this.closeDrawer.bind(this));
    });
    window.addEventListener('scroll', throttle(this.pagescroll.bind(this), 250));
    window.addEventListener('load', this.handlePageLoad.bind(this));
  }

  handlePageLoad() {
    const hash = window.location.hash;
    if (hash) {
      this.smoothScrollToAnchor(hash);
    }
  }

  smoothScrollToAnchor(selector) {
    const targetElement = document.querySelector(selector);
    if (targetElement) {
      setTimeout(() => {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 200);
    }
  }

  closeDrawer() {
    this.headerDrawer.classList.remove('open');
    this.hamburgerToggle.forEach((el) => {
      el.classList.remove('open');
      el.setAttribute('aria-expanded', false);
    });
    document.body.classList.remove('-is-scroll-locked');
  }

  toggleMobileMenu() {
    this.headerDrawer.classList.toggle('open');
    let isOpen = this.headerDrawer.classList.contains('open');
    this.hamburgerToggle.forEach((el) => {
      el.classList.toggle('open');
      el.setAttribute('aria-expanded', isOpen);
    });
    document.body.classList.toggle('-is-scroll-locked', isOpen);
  }

  pagescroll() {
    let body = document.body;
    let header = this.element;
    let footer = document.querySelector('footer.site-footer');
    let viewport = {
      top: window.scrollY,
      bot: window.scrollY + window.innerHeight,
    };

    // Page has started scrolling
    let shouldToggle = (
      !body.classList.contains('stories') &&
      !body.classList.contains('press')
    );
    if (shouldToggle) {
      let isScrolling = viewport.top > 320;
      body.classList.toggle('scroll-header', isScrolling);
      header.classList.toggle('scroll-header', isScrolling);
    }

    // Page is at the bottom near the footer
    let nearBottom = viewport.bot > footer.offsetTop;
    body.classList.toggle('-is-bottom', nearBottom);
  }
}
