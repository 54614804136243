import { Controller } from 'stimulus'
import { handleSubmitForm } from 'shared/assets/javascript/contact_form_7'

export default class extends Controller {

  connect() {
    this.formTarget = this.element.querySelector('form')
  }

  async submit(e) {
    e.preventDefault()
    console.log(this.formTarget)
    let response = await handleSubmitForm(this.formTarget)
    console.log({response})
    if (response.status !== 'mail_sent') return
    this.element.classList.add('-form-success')
    setTimeout(() => {
      this.element.classList.remove('-form-success')
      this.element.querySelector('#email_address').value = ''
    }, 5000)
  }

}
